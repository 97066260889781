import { Puzzle } from './types'

const todayFilename = () => {
  const today = new Date()
  const month = (today.getMonth()+1).toString().padStart(2, '0')
  const date = today.getDate().toString().padStart(2, '0')
  return `${today.getFullYear()}-${month}-${date}`
}

const fetchTodayPuzzleAtPath = async (path: string) => {
  const dateString = todayFilename()
  const response = await fetch(path)

  const puzzles = await response.json()
  const puzzleString = puzzles[dateString] as string
  const puzzleStringParts = puzzleString.split('.')
  const puzzleStringInts = puzzleStringParts.map(s => parseInt(s, 10))

  const [target, ...numbers] = puzzleStringInts
  const puzzle = { target, numbers } as Puzzle

  return { dateString, puzzle }
}

const fetchTodayPuzzle = async () => {
  const path = `/puzzles/${new Date().getFullYear()}.json`
  return fetchTodayPuzzleAtPath(path)
}

const fetchTodayJuniorPuzzle = async () => {
  const path = `/puzzles/${new Date().getFullYear()}-junior.json`
  return fetchTodayPuzzleAtPath(path)
}

export {
  todayFilename,
  fetchTodayPuzzle,
  fetchTodayJuniorPuzzle,
}
