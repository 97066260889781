import { useEffect, useRef, useState } from "react"
import Board from './Board'
import useGameState from './useGameState'
import { Puzzle } from './types'

const DEMO_PUZZLE = {
  target: 660,
  numbers: [100, 9, 6, 2, 8, 5],
} as Puzzle

const DEMO_PUZZLE_INFO = { puzzle: DEMO_PUZZLE }

const Demo: React.FC<{}> = () => {
  const gameState = useGameState({ puzzleInfo: DEMO_PUZZLE_INFO })
  const [count, setCount] = useState(0)
  const tickRef = useRef<() => void>()
  tickRef.current = () => {
    if (!gameState) return

    const steps = [
      () => gameState.handleSourceNumberClick(1),
      () => gameState.handleOperatorClick('+'),
      () => gameState.handleSourceNumberClick(0),
      () => gameState.handleOperationResultClick(gameState.operations[0].id),
      () => gameState.handleOperatorClick('*'),
      () => gameState.handleSourceNumberClick(2),
      () => gameState.handleSourceNumberClick(4),
      () => gameState.handleOperatorClick('-'),
      () => gameState.handleSourceNumberClick(3),
      () => gameState.handleOperationResultClick(gameState.operations[1].id),
      () => gameState.handleOperatorClick('+'),
      () => gameState.handleOperationResultClick(gameState.operations[2].id),
    ]

    const step = steps[count]
    if (step) {
      step()
      return true
    }
    return false
  }

  useEffect(() => {
    const timeout = setInterval(() => {
      if (!tickRef.current?.()) {
        clearTimeout(timeout)
      }
      setCount(c => c + 1)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [setCount, tickRef])

  return (
    <Board
      gameState={gameState}
      puzzle={DEMO_PUZZLE}
    />
  )

}
export default Demo