export default function shuffleArray(a: number[]) {
  const copy = a.slice()
  var n = copy.length;

  for(var i = n - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = copy[i];
      copy[i] = copy[j];
      copy[j] = tmp;
  }
  return copy
}