import { useEffect, useState } from 'react' ;
import { GameState, OperatorType, OperationType, Operand, OperandSourceType, PuzzleInfo } from './types'

const makeBlankOperation = () => ({
  left: null,
  right: null,
  operator: null,
  id: (new Date()).getTime(),
})

const INITIAL_STATE = [makeBlankOperation()]

function useGameState({ puzzleInfo }:  { puzzleInfo: PuzzleInfo | undefined }) {
  const [operations, setOperations] = useState<GameState>()

  useEffect(() => {
    if (!puzzleInfo) return

    setOperations(puzzleInfo.initialGameState || INITIAL_STATE)
  }, [puzzleInfo])

  if (!puzzleInfo || !operations) return null

  const { puzzle } = puzzleInfo

  const shouldAddBlankOperation = (newOperations: OperationType[]) => {
    if (newOperations.length === puzzleInfo.puzzle.numbers.length - 1) return false
    const last = newOperations[newOperations.length - 1]
    if (!last) return true
    const value = evaluateOperationValue(last)
    if (value == null) return false
    if (isValidResult(value)) {
      if (value === puzzle.target) return false
      return true
    }
    return false
  }

  const addBlankOperationIfNecessary = (newOperations: OperationType[]) => {
    if (shouldAddBlankOperation(newOperations)) {
      newOperations.push(makeBlankOperation())
    }
  }

  const handleUpdatedOperation = (operation: OperationType) => {
    const remainingOperations = operations.slice(0, operations.length - 1)

    const newOperations = [...remainingOperations, operation]
    addBlankOperationIfNecessary(newOperations)
    setOperations(newOperations)
  }

  const applyNewOperandToCurrentOperation = (operand: Operand) => {
    const currentOperation = operations[operations.length - 1]
    if (!currentOperation.left) {
      handleUpdatedOperation({
        ...currentOperation,
        left: operand,
      })
    }
    else if (!currentOperation.right) {
      handleUpdatedOperation({
        ...currentOperation,
        right: operand,
      })
    }
  }

  const handleSourceNumberClick = (id: number) => {
    if (isNumberCardUsed(id, "source")) return

    applyNewOperandToCurrentOperation({ id, type: "source" as const })
  }

  const handleOperationResultClick = (id: number) => {
    if (isNumberCardUsed(id, "computed")) return

    applyNewOperandToCurrentOperation({ id, type: "computed" as const })
  }
  
  const handleOperatorClick = (operator: OperatorType) => {
    const currentOperation = operations[operations.length - 1]
    handleUpdatedOperation({ 
      ...currentOperation,
      operator,
    })
  }

  const handleClickCurrentOperand = (operand: Operand) => {
    const currentOperation = operations[operations.length - 1]
    if (currentOperation.left === operand) {
      handleUpdatedOperation({
        ...currentOperation,
        left: null,
      })
    }
    else if (currentOperation.right === operand) {
      handleUpdatedOperation({
        ...currentOperation,
        right: null,
      })
    }
  }

  const handleClickCurrentOperator = () => {
    const currentOperation = operations[operations.length - 1]
    handleUpdatedOperation({ 
      ...currentOperation,
      operator: null,
    })
  }

  const handleDeleteClick = (id: number) => {
    const markedForDelete = [id]

    let currentId: (number | undefined) = id

    while(currentId) {
      const _currentId: number = currentId
      const operationToDelete = operations.find(operation => {
        return (
          (operation.left?.id === _currentId && operation.left?.type === 'computed') ||
          (operation.right?.id === _currentId && operation.right?.type === 'computed')
        )  
      })
      
      if (operationToDelete) {
        markedForDelete.push(operationToDelete.id)
      }
      currentId = operationToDelete?.id
    }

    const newOperations = operations.filter(operation => !markedForDelete.includes(operation.id))
    addBlankOperationIfNecessary(newOperations)
    setOperations(newOperations)
  }

  const reset = () => {
    setOperations(INITIAL_STATE)
  }

  const valueForOperand = (operand: Operand): number => {
    if (operand.type === 'source') {
      return puzzle.numbers[operand.id]
    } else {
      const operation = operations.find(op => op.id === operand.id)
      if (!operation) {
        throw new Error("Couldn't find operation")
      }
      const value = evaluateOperationValue(operation)
      if (value == null) {
        throw new Error("operand value is null")
      }
      return value
    }
  }

  const isValidResult = (value: number) => {
    return value > 0 && value === Math.floor(value)
  }

  const evaluateOperationValue = (operation: OperationType): number | null=> {
    if (!operation.left || !operation.right || !operation.operator) {
      return null
    }
    const leftOperandValue = valueForOperand(operation.left)
    const rightOperandValue = valueForOperand(operation.right)
    switch(operation.operator) {
      case '+':
        return leftOperandValue + rightOperandValue
      case '-':
        return leftOperandValue - rightOperandValue
      case '*':
        return leftOperandValue * rightOperandValue
      case '/':
        return leftOperandValue / rightOperandValue
    }
  }

  const isNumberCardUsed = (id: number, type: OperandSourceType) => {
    return Boolean(operations.find(operation => {
      if (operation.left?.id === id && operation.left?.type === type) {
        return true
      } else if (operation.right?.id === id && operation.right?.type === type) {
        return true
      }
      return false
    }))
  }

  const isSolved = () => {
    const last = operations[operations.length - 1]
    if (!last) return false
    const value = evaluateOperationValue(last)
    return value === puzzle.target
  }

  return {
    operations,
    handleSourceNumberClick,
    handleOperationResultClick,
    handleOperatorClick,
    handleClickCurrentOperator,
    handleDeleteClick,
    handleClickCurrentOperand,
    evaluateOperationValue,
    valueForOperand,
    isNumberCardUsed,
    isValidResult,
    reset,
    isSolved,
  }
}

export default useGameState 