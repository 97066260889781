import { Puzzle } from './types'
import shuffle from './shuffleArray'
import solve, { countCardsUsed } from './solver'

const BIG_NUMBERS = [25, 50, 75, 100]
const SMALL_NUMBERS = [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10]

const generatePuzzle = () => {
  var bigNumbers = shuffle(BIG_NUMBERS)
  var smallNumbers = shuffle(SMALL_NUMBERS)

  let numBig = Math.floor(Math.random() * 5)
  let numSmall = 6 - numBig

  const numbers = []
  while(numBig--) numbers.push(bigNumbers.pop())
  while(numSmall--) numbers.push(smallNumbers.pop())

  const target = Math.floor(Math.random() * (899)) + 101

  return {
    target,
    numbers,
  } as Puzzle
}

function generateKidsPuzzle() {
  var smallNumbers = shuffle(SMALL_NUMBERS)
  
  const numbers = new Array(4).fill(true).map(() => smallNumbers.pop())
  const target = Math.floor(Math.random() * 89) + 11
  return {
    target,
    numbers,
  } as Puzzle
}

const generateSolvablePuzzle = () => {
  let puzzle: Puzzle
  while(true) {
    puzzle = generatePuzzle()
    const solutions = solve(puzzle.numbers, puzzle.target)
    if (!solutions.length) continue
    break
  }
  return puzzle
}

const generateSolvableInterestingKidsPuzzle = () => {
  let puzzle: Puzzle
  while(true) {
    puzzle = generateKidsPuzzle()
    const solutions = solve(puzzle.numbers, puzzle.target)
    if (!solutions.length) continue

    // too boring if not all cards are required
    if (solutions.some(s => countCardsUsed(s) < 4)) continue
    break
  }
  return puzzle
}

export default generatePuzzle
export { generateSolvablePuzzle, generateSolvableInterestingKidsPuzzle }