import './App.css'
import { useEffect, useState } from 'react'
import { PuzzleInfo } from './types'
import Header from './Header'
import { getStateFromStorage, setStateToStorage } from './storage'
import useTimer from './useTimer'
import { fetchTodayPuzzle, fetchTodayJuniorPuzzle } from './fetchPuzzle'
import { generateSolvablePuzzle, generateSolvableInterestingKidsPuzzle } from './generatePuzzle'
import useGameState from './useGameState'
import Board from './Board'
import WinModal from './WinModal'
import { useTrackingContext } from './TrackingContextProvider'

function App() {
  const { track } = useTrackingContext()
  const [puzzleInfo, setPuzzleInfo] = useState<PuzzleInfo>()
  const { time, start: timerStart, stop: timerStop, set: timerSet } = useTimer()
  const [winModalOpen, setWinModalOpen] = useState(false)

  const gameState = useGameState({ puzzleInfo })
  const solved = gameState?.isSolved()
  const { operations } = gameState || {}

  useEffect(() => {
    if (operations && time >= 0 && puzzleInfo?.puzzleId && puzzleInfo.storageKey) {
      setStateToStorage(puzzleInfo.storageKey, {
        gameState: operations,
        time: time,
        puzzleId: puzzleInfo.puzzleId,
      })
    }
  }, [operations, time, puzzleInfo])

  useEffect(() => {
    const handler = () => {
      if (solved) return
      document.visibilityState === "visible" ? timerStart() : timerStop()
    }

    document.addEventListener("visibilitychange", handler)

    return () => document.removeEventListener("visibilitychange", handler)
  }, [solved, timerStart, timerStop])
  
  useEffect(() => {
    if (window.location.pathname === '/random') {
      timerSet(0)
      setPuzzleInfo({ puzzle: generateSolvablePuzzle() })
      return
    }
    if (window.location.pathname === '/kids') {
      timerSet(0)
      setPuzzleInfo({ puzzle: generateSolvableInterestingKidsPuzzle() })
      return
    }

    (async () => {
      try {
        const { dateString: puzzleId, puzzle, storageKey } =  await (async () => {
          if (window.location.pathname === '/junior') {
            const result = await fetchTodayJuniorPuzzle()
            return {
              storageKey: 'junior-daily',
              ...result,
            }
          } else {
            const result = await fetchTodayPuzzle()
            return {
              storageKey: 'daily',
              ...result,
            }
          }
        })()

        const storedState = getStateFromStorage(storageKey, puzzleId)

        timerSet(storedState?.time || 0)

        setPuzzleInfo({
          puzzle,
          storageKey,
          puzzleId,
          initialGameState: storedState?.gameState,
        })
      } catch(e) {
        timerSet(0)
        setPuzzleInfo({ puzzle: generateSolvablePuzzle() })
      }
    })()
  }, [setPuzzleInfo, timerSet])
  
  useEffect(() => {
    if (solved === undefined) return
    if (!puzzleInfo) return

    let timeout: ReturnType<typeof setTimeout>
    if (solved) {
      timerStop()
      track('solved')
      if (puzzleInfo.storageKey) {
        timeout = setTimeout(() => {
          setWinModalOpen(true)
        }, 1000)
      }
    } else {
      timerStart()
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
    
  }, [solved, timerStart, timerStop, setWinModalOpen, track, puzzleInfo])

  if (!puzzleInfo || !gameState || time < 0) return null

  return (
    <div style={{'--caxrd-color': 'var(--foo-color)'} as React.CSSProperties}>
      <Header time={time} />
      <Board
        gameState={gameState}
        puzzle={puzzleInfo.puzzle}
      />
      {puzzleInfo.storageKey && (
        <WinModal
          isOpen={Boolean(winModalOpen)}
          time={time}
          onRequestClose={() => setWinModalOpen(false)}
          storageKey={puzzleInfo.storageKey}
          targetNumber={puzzleInfo.puzzle.target}
        />
      )}
      
    </div>
  )
}

export default App