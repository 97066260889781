type SolutionNumberCard = {
  left: SolutionNumberCard | null
  right: SolutionNumberCard | null
  value: number
  operator: number | null
}

const _solve = (numberCards: SolutionNumberCard[], target:number, solutions: SolutionNumberCard[], memo = {}) => {
  const solution = numberCards.find(card => card.value === target)
  if (solution) {
    solutions.push(solution)
    return
  }

  for (let i = 0; i < numberCards.length - 1; i++) {
    for (let j = i+1; j < numberCards.length; j++) {
      let [left, right] = [numberCards[i], numberCards[j]]
      if (left.value < right.value) {
        [left, right] = [right, left]
      }

      const restNumberCards = numberCards.filter((_, k) => k !== i && k !== j)

      for (let op = 0; op < 4; op++) {
        const newValue = (() => {
          switch(op) {
            case 0: return left.value + right.value
            case 1: return left.value - right.value
            case 2: return left.value * right.value
            case 3: return (left.value % right.value) ? -1 : (left.value / right.value)
            default: return -1
              
          }
        })()
        
        if (newValue <= 0) continue

        const newNumberCard = {
          value: newValue,
          left,
          right,
          operator: op,
        }

        const nextCards = [newNumberCard, ...restNumberCards]
        _solve(nextCards, target, solutions)
      }
    }
  }
}

const solve = (numbers: number[], target: number) => {
  const solutions:SolutionNumberCard[] = []

  const numberCards = numbers.map((n) => ({
    value: n,
    left: null,
    right: null,
    operator: null,
  }))

  _solve(numberCards, target, solutions)

  return solutions
}

const countCardsUsed = (card: SolutionNumberCard): number => {
  if (!card.left || !card.right) return 1

  return countCardsUsed(card.left) + countCardsUsed(card.right)
}

export default solve

export { countCardsUsed }