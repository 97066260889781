import { useState } from 'react'
import ReactModal from 'react-modal';
import Demo from './Demo'
import timeDisplay from './timeDisplay'

ReactModal.defaultStyles = {overlay: {}, content: {}}

type Props = {
  time: number
}

const Header: React.FC<Props> = ({ time }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <header className="app-header">
        <h1 className="app-header__h1">COUNTLE</h1>
        <button className="app-header__help" onClick={() => setModalOpen(true)}>?</button>
        <div className="app-header__timer">{timeDisplay(time)}</div>

        <ReactModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          closeTimeoutMS={200}
        >
          <h2 className="modal__h2">How to play</h2>
          <p>
            Combine the given numbers using addition, subtraction, multiplication and division to reach the target.
          </p>
          <p>
            No negative numbers or fractions allowed!
          </p>

          <div className="modal__demo-container">
            <div className="modal__demo">
              <Demo />
            </div>
          </div>
        </ReactModal>
      </header>
    </>
  )
}

export default Header