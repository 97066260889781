import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebaseSetup from './firebaseSetup';
import { TrackingContextProvider, makeTrack } from './TrackingContextProvider'

const analytics = firebaseSetup()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <TrackingContextProvider value={makeTrack(analytics)}>
    <App />
  </TrackingContextProvider>);

ReactModal.setAppElement('#root')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
