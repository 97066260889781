// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default () => {
  if (process.env.NODE_ENV !== 'production') return null

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyDncrFSXp8BbwE8BBX22pVSNAlUPWiBDTA",
    authDomain: "countle-7cb56.firebaseapp.com",
    projectId: "countle-7cb56",
    storageBucket: "countle-7cb56.appspot.com",
    messagingSenderId: "1003450227119",
    appId: "1:1003450227119:web:e081fd57bbdcc820faf75a",
    measurementId: "G-J7RYY2FST2"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return analytics
}