import { useCallback, useEffect, useRef, useState } from 'react'

const useTimer = () => {
  const timeoutRef = useRef<ReturnType<typeof setInterval>>()

  const [time, setTime] = useState(-1)

  const set = useCallback((t:number) => {
    setTime(t)
  }, [setTime])
  
  const start = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setInterval(() => {
      setTime(c => (c || 0) + 1)
    }, 1000)
  }, [timeoutRef, setTime])

  const stop = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = undefined
  }, [timeoutRef])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [timeoutRef])

  return {
    set,
    start,
    stop,
    time,
  }
}

export default useTimer