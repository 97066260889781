import React, { useRef, useState, useEffect } from "react"


export enum STATE {
  Empty = 'Empty',
  Filled = 'Filled',
  Locked = 'Locked',
  Problem = 'Problem',
  Solved = 'Solved',
}

export enum TYPE {
  Source = 'Source',
  Operator = 'Operator',
  OperatorSource = 'OperatorSource',
  Operand = 'Operand',
  Computed = 'Computed',
}
type HTMLButtonElementType = JSX.IntrinsicElements['button']
interface Props extends HTMLButtonElementType {
  state: STATE
  operator?: boolean
  label: string
  cardType: TYPE
}

function useTemporaryState<T>(initial: T) {
  const result = useState<T | undefined>(initial)
  const [value, setValue] = result
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (value) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      const timeout = setTimeout(() => {
        setValue(undefined)
      }, 500)

      timeoutRef.current = timeout

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [value, timeoutRef])

  return result
}

export const NumberCard: React.FC<Props> = ({ state, operator, label, cardType, onClick, ...props }) => {
  const prevState = useRef<{
    state: STATE,
    label: string
  }>()
  const [recentChange, setRecentChange] = useTemporaryState<string>('')

  useEffect(() => {
    if (prevState.current) {
      (() => {
        if (prevState.current.state === state && prevState.current.label === label) {
          // no change
          return
        }

        if (cardType === TYPE.Computed && prevState.current.state === STATE.Empty && state === STATE.Filled) {
          setRecentChange('anim-flipout')
          return
        }
        
        if (prevState.current.label !== label && state !== STATE.Solved) {
          setRecentChange(label ? 'anim-popin' : 'anim-popout')
          return
        }
        
        if (cardType === TYPE.Source || cardType === TYPE.Computed) {
          if (prevState.current.state === STATE.Locked && state === STATE.Filled) {
            setRecentChange('anim-popin')
          } else if (prevState.current.state === STATE.Filled && state === STATE.Locked) {
            setRecentChange('anim-popout')
          }
        }
      })()
    }

    prevState.current = { state, label }
  }, [state, label, cardType])

  const classNames = ["number-card"]
  if (operator) classNames.push("number-card--operator")
  if (state === STATE.Empty) classNames.push('number-card--empty')
  if (state === STATE.Locked) classNames.push('number-card--locked')
  if (state === STATE.Problem) classNames.push('number-card--problem')
  if (state === STATE.Solved) classNames.push('number-card--solved')
  if (state === STATE.Filled) classNames.push('number-card--filled')
  if (recentChange) classNames.push(`number-card--${recentChange}`)
  if (label.length >=3) {
    const len = Math.min(label.length, 5)
    classNames.push(`number-card--${len}-chars`)
  }

  const handleClick: Props['onClick'] = (e) => {
    if (cardType === TYPE.OperatorSource) setRecentChange('anim-popout')
    onClick?.(e)
  }

  const disabled = state !== STATE.Filled
  return (
    <button
      className={classNames.join(' ')}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  )
}

export default NumberCard
