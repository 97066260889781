import { GameState } from './types'

type StoredState = {
  gameState: GameState,
  time: number,
  puzzleId: string,
}

const setStateToStorage = (storageKey: string, state: StoredState) => {
  localStorage.setItem(storageKey, JSON.stringify(state))
}

const getStateFromStorage = (storageKey: string, puzzleId: string) => {
  try {
    const stateJson = localStorage.getItem(storageKey)
    if (!stateJson) return undefined

    const state = JSON.parse(stateJson) as StoredState
    if (state.puzzleId !== puzzleId) return undefined
    return {
      gameState: state.gameState,
      time: state.time,
    }
  } catch(e) {
    return undefined
  }
}


export { getStateFromStorage, setStateToStorage }