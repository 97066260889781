import './App.css'
import { OperatorType, Operand, Puzzle } from './types'
import NumberCard, { STATE, TYPE } from './NumberCard'
import DeleteIcon from './DeleteIcon'
import useGameState from './useGameState'

const OPERATOR_LIST:OperatorType[] = ['+','-','*','/']
const OPERATOR_GLYPHS = {
  '+': '+',
  '-': '−',
  '*': '×',
  '/': '÷',
}

interface Props {
  puzzle: Puzzle
  gameState: ReturnType<typeof useGameState>
}

const Board: React.FC<Props> = ({
  puzzle,
  gameState,
}) => {
  if (!gameState) return null

  const {
    operations,
    handleSourceNumberClick: onSourceNumberClick,
    handleOperationResultClick: onOperationResultClick,
    handleOperatorClick: onOperatorClick,
    handleClickCurrentOperator: onCurrentOperatorClick,
    handleDeleteClick: onDeleteClick,
    handleClickCurrentOperand: onCurrentOperandClick,
    evaluateOperationValue,
    valueForOperand,
    isNumberCardUsed,
    isValidResult,
    isSolved,
  } = gameState

  const renderOperand = (operand: Operand | null, isCurrentOperation: boolean) => {
    const value = operand ? valueForOperand(operand) : ''
    const state = (() => {
      if (!isCurrentOperation) return STATE.Locked
      if (operand) return STATE.Filled
      return STATE.Empty
    })()

    const onClick = () => {
      if (!isCurrentOperation) return
      if (!operand) return

      onCurrentOperandClick(operand)
    }

    return (
      <NumberCard
        state={state}
        onClick={onClick}
        label={`${value}`}
        cardType={TYPE.Operand}
      />
    )
  }

  const renderOperator = (operator: OperatorType | null, isCurrentOperation: boolean) => {
    const state = (() => {
      if (!isCurrentOperation) return STATE.Locked
      if (operator) return STATE.Filled
      return STATE.Empty
    })()

    const onClick = () => {
      if (!isCurrentOperation) return
      if (!operator) return

      onCurrentOperatorClick()
    }

    return (
      <NumberCard
        state={state}
        onClick={onClick}
        label={operator ? OPERATOR_GLYPHS[operator] : ''}
        operator
        cardType={TYPE.Operator}
      />
    )
  }

  const solved = isSolved()

  return (
    <div className="App">
      <div className={`board ${solved ? 'board--solved' : ''}`}>
        <div className={`target ${solved ? 'target--solved' : ''}`}>{puzzle.target}</div>

        <div className="source-cards">
          {puzzle.numbers.map((value, i) => (
            <NumberCard
              key={i}
              onClick={() => onSourceNumberClick(i)}
              state={isNumberCardUsed(i, "source") ? STATE.Locked : STATE.Filled}
              label={`${value}`}
              cardType={TYPE.Source}
            />
          ))}
        </div>

        <div className="operators">
          {OPERATOR_LIST.map((op) => (
            <NumberCard
              key={op}
              state={STATE.Filled}
              onClick={() => onOperatorClick(op)}
              label={OPERATOR_GLYPHS[op]}
              operator
              cardType={TYPE.OperatorSource}
            />
          ))}
        </div>

        <div>
          {operations.map((operation, i) => {
            const isCurrentOperation = (i === operations.length - 1)
            const isFilled = Boolean((operation.left && operation.operator && operation.right))
            const result = isFilled ? evaluateOperationValue(operation) : null
            const invalidResult = result !== null && !isValidResult(result)

            const [resultText, resultState] = (() => {
              if (result === null) return ['', STATE.Empty]
              if (invalidResult) return ['∉ℕ', STATE.Problem]
              if (result === puzzle.target) return [result.toString(), STATE.Solved]
              if (isNumberCardUsed(operation.id, "computed")) return [result.toString(), STATE.Locked]
              return [result.toString(), STATE.Filled]
            })()

            const hideDelete = !operation.left && !operation.right && !operation.operator

            return (
              <div key={operation.id} className="operation">
                {renderOperand(operation.left, isCurrentOperation)}
                {renderOperator(operation.operator, isCurrentOperation)}
                {renderOperand(operation.right, isCurrentOperation)}
                <div className="equals-sign">=</div>
                
                <NumberCard
                  onClick={() => onOperationResultClick(operation.id)}
                  state={resultState}
                  label={resultText}
                  cardType={TYPE.Computed}
                />
                <DeleteIcon
                  onClick={() => onDeleteClick(operation.id)}
                  hide={hideDelete}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Board
