import React from 'react'
import ReactModal from 'react-modal'
import timeDisplay from './timeDisplay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'

type Props = {
  onRequestClose: () => void
  isOpen: boolean
  time: number
  storageKey: string
  targetNumber: number
}

const WinModal: React.FC<Props> = ({ onRequestClose, isOpen, time, storageKey, targetNumber }) => {
  const [winText, shareText] = (() => {
    switch(storageKey) {
      case 'junior-daily':
        return [
          `You solved today's Countle Junior in ${timeDisplay(time)}`,
          `I solved today's #CountleJunior in ${timeDisplay(time)} — https://countle.org/junior`,
        ]
      default:
        return [
          `You solved today's Countle in ${timeDisplay(time)}`,
          `I solved today's #Countle ${targetNumber} in ${timeDisplay(time)} — https://countle.org`,
        ]
    }
  })()

  const handleClick = () => {
    try {
      navigator.share({ text: shareText })
      return
    } catch(e) {
      // continue ...
    }

    try {
      navigator.clipboard.writeText(shareText).then(() =>{
        alert("Copied to clipboard")
      }, () => {
        alert("Couldn't share")
      })
    } catch(e) {
      alert("Couldn't share")
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
    >
      <h2 className="modal__h2">Great job!</h2>
      <p>
        {winText}
      </p>

      <div className="share-button-container">
        <button
          className="share-button"
          onClick={handleClick}
        >
          Share <FontAwesomeIcon icon={faShareNodes} />
        </button>
      </div>

    </ReactModal>
  )
}

export default WinModal