import { createContext, useContext } from 'react'
import { getAnalytics, logEvent } from "firebase/analytics";

type TrackingFunc = (eventName: string, eventParams?: { [key: string]: any }) => void
const TrackingContext = createContext<TrackingFunc>(() => {})

export const useTrackingContext = () => {
  const track = useContext(TrackingContext)
  return { track }
}

export const TrackingContextProvider = TrackingContext.Provider

export const makeTrack = (analytics: ReturnType<typeof getAnalytics> | null): TrackingFunc => {
  return (eventName, eventParams?) => {
    if (analytics) {
      logEvent(analytics, eventName, eventParams);
    } else {
      console.log(eventName, eventParams || '')
    }
  }
}